import moment, { Moment } from "moment";
import { dedup } from "./ArrayUtils";
import { DateScale } from "../components/UI/molecules/DateFlicker";

export const DATE_FORMAT = 'YYYY-MM-DD';
export const PRETTY_DATE_FORMAT = 'dddd Do MMMM';
export const PRETTY_SHORT_DATE_FORMAT = 'Do MMMM YYYY';
export const PRETTY_DATE_TIME_FORMAT = 'hh:mm Do MMM YYYY';

export const durationSince = (date: Date) => {
  const end = moment(date);
  const now = moment();
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  if (days > 1) {
    return `${Math.floor(days)} days ago`
  } else if (duration.asHours() > 1) {
    return `${Math.floor(duration.asHours())} hours ago`
  } else if (duration.asMinutes() > 1) {
    return `${Math.floor(duration.asMinutes())} minutes ago`
  } else {
    return 'just now';
  }
}

export const prettyDateTime = (date: Date) => {
  return moment(date).format(PRETTY_DATE_TIME_FORMAT);
}

export function workdayCount(start: Moment | null, end: Moment | null) {
  if (!start || !end) return 0;

  const first = start.clone().endOf('week');
  const last = end.clone().startOf('week');
  const days = last.diff(first,'days') * 5 / 7;
  let wfirst = first.day() - start.day();
  if(start.day() === 0) --wfirst;
  let wlast = end.day() - last.day();
  if(end.day() === 6) --wlast;
  return wfirst + Math.floor(days) + wlast;
}

export function getListOfDaysBetween(from: Moment | null, to: Moment | null): Moment[] {
  if (!from || !to) return [];

  const numberOfDaysBetween = to.diff(from,'days');
  if (numberOfDaysBetween <= 1) return [];

  let daysBetween: Moment[] = [];
  for (let i = 1; i < numberOfDaysBetween; i++) {
    daysBetween.push(from.clone().add(i, 'days'))
  }

  return daysBetween;
}

export function getListOfDaysBetweenInclusive(from?: Moment | null, to?: Moment | null): Moment[] {
  if (!from || !to) return [];

  if (to.isBefore(from)) {
    throw new Error('To date must not be before from date');
  }

  const numberOfDaysBetween = to.diff(from,'days');
  if (numberOfDaysBetween < 1) return [from.clone()];

  let daysBetween: Moment[] = [];
  for (let i = 0; i < numberOfDaysBetween; i++) {
    daysBetween.push(from.clone().add(i, 'days'))
  }
  daysBetween.push(to.clone());

  const dedupedArray = dedup(daysBetween, (a: Moment, b: Moment) => a.format(DATE_FORMAT) === b.format(DATE_FORMAT));
  return dedupedArray;
}

export function isWeekend(date?: Moment) {
  if (!date) return false;
  return (date.isoWeekday() === 7 || date.isoWeekday() === 6);
}

export function daysBetween(a?: string | Moment, b?: string | Moment): number {
  if (!a || !b) return 0;
  return moment(a).diff(moment(b), 'days');
}

export function printDate(date: Moment | string) {
  return moment(date).format(DATE_FORMAT);
}

export function isDateBefore(a?: Moment, b?: Moment) {
  if (!a || !b) return false;
  return a.isBefore(b);
}

export function isDateInRange(queryDate: Moment, start: Moment, end: Moment) {
  return queryDate.isBetween(start.subtract(1, 'days'), end.add(1, 'days'), 'days');
}

export function getFirstMondayInMonth(date: Moment) {
  let firstMonday = date.startOf('month');
  if (firstMonday.day() === 0) {
    return firstMonday.day(1);
  } else if (firstMonday.day() === 1) {
    return firstMonday;
  } else {
    return firstMonday.day(firstMonday.day() + (8 - firstMonday.day()));
  }
}

export function toAirBnbDate(from: Moment, to: Moment): string {
  if (from.year() === to.year() && from.month() === to.month() && from.date() === to.date()) {
    return from.format('D MMM YYYY');
  } else if (from.year() === to.year() && from.month() === to.month()) {
    return `${from.format('D')} - ${to.format('D MMM YYYY')}`
  } else if (from.year() === to.year()) {
    return `${from.format('D MMM')} - ${to.format('D MMM YYYY')}`
  } else {
    return `${from.format('D MMM YYYY')} - ${to.format('D MMM YYYY')}`
  }
}

export function toSingleDateFormat(date: Moment, dateScale: DateScale): string {
  switch (dateScale) {
    case DateScale.Day: return date.clone().startOf('day').format('Do');
    case DateScale.Week: return date.clone().startOf('isoWeek').format('Do MMMM');
    case DateScale.Month: return date.clone().startOf('month').format('MMMM YYYY');
    case DateScale.Year: return date.clone().startOf('year').format('MMMM YYYY');
    default: return date.format(DATE_FORMAT);
  }
}
