import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { BodyRegular } from "../atoms/fonts/Body";
import { Row } from "../atoms/StructuralLayout";
import { setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { useDispatch } from "react-redux";
import Colours from "../atoms/Colours";
import { toAirBnbDate, toSingleDateFormat } from "../../../utils/DateUtils";
import { DialogIdentifiers, openDialog } from "../../../store/ducks/dialog.duck";

export enum DateScale {
  Day, Week, Month, Year,
}

interface Props {
  dateScale: DateScale;
  onChange?: (date: Moment) => any
  updateGlobalDate: boolean;
  date?: Moment;
  showDateRange?: boolean;
  fontWeight?: number
  disabled?: boolean;
  dateInTheMiddle?: boolean;
  hideDateText?: boolean;
  dateFormat?: string;
  dateLabelOverride?: string;
  style?: any;
}

export default function DateFlicker(props: Props) {
  const {showDateRange, dateLabelOverride, dateInTheMiddle,
    dateScale, dateFormat, fontWeight, updateGlobalDate,
    disabled, hideDateText} = props;

  const [date, setDate] = useState<Moment>(moment());
  const dispatch = useDispatch();

  const setReduxCalendarDate = (moment: Moment) => {
    if (updateGlobalDate) {
      dispatch(setCalendarDate(moment));
    }
  }

  useEffect(() => {
    setDate(props.date ?? moment())
  }, [props.date])

  const changeDate = (direction: number) => {
    if (disabled) {
      return;
    }

    const getChangedDate = (): Moment|null => {
      switch (props.dateScale) {
        case DateScale.Day: return date.clone().add(direction, 'day');
        case DateScale.Week: return date.clone().add(direction, 'week');
        case DateScale.Month: return date.clone().add(direction, 'month');
        case DateScale.Year: return date.clone().add(direction, 'year');
        default: return null;
      }
    }

    const newDate = getChangedDate();
    if (newDate) {
      setDate(newDate);
      setReduxCalendarDate(newDate);
      if (props.onChange) {
        props.onChange(newDate);
      }
    }
  }

  const dateLabel = useMemo(() => {
    const getStartText = (textDate: Moment) => {
      switch (dateScale) {
        case DateScale.Day: return textDate.clone().startOf('day');
        case DateScale.Week: return textDate.clone().startOf('isoWeek');
        case DateScale.Month: return textDate.clone().startOf('month');
        case DateScale.Year: return textDate.clone().startOf('year');
        default: return textDate;
      }
    }
    const getEndText = (textDate: Moment) => {
      switch (dateScale) {
        case DateScale.Day: return textDate.clone().endOf('day');
        case DateScale.Week: return textDate.clone().endOf('isoWeek');
        case DateScale.Month: return textDate.clone().endOf('month');
        case DateScale.Year: return textDate.clone().endOf('year');
        default: return textDate;
      }
    }

    if (dateLabelOverride) {
      return dateLabelOverride;
    } else if (showDateRange) {
      console.log(date.toString())
      return toAirBnbDate(getStartText(date), getEndText(date));
    } else if (dateFormat) {
      return date.format(dateFormat)
    } else {
      return toSingleDateFormat(date, dateScale);
    }
  }, [showDateRange, dateFormat, dateScale, date, dateLabelOverride]);

  return (
    <div className={`weekFlicker ${props.className}`.trim()} style={props?.style ?? {}}>
      <div className="weekFlicker__control">

        <Icon circle={true}
              className={dateInTheMiddle ? '' : 'weekFlicker__control--mobile'}
              icon={IconTypes.Previous}
              color={props.disabled ? 'grey' : 'black'}
              style={{marginLeft: 0}}
              onClick={() => changeDate(-1)}
              dataTest="week-flicker-previous"/>

        {!hideDateText && <>
          <BodyRegular weight={fontWeight ?? 600}
                       colour={props.disabled ? Colours.mildGrey : Colours.black}
                       className="weekFlicker__dateLabel weekFlicker__dateLabel--desktop">
            {dateLabel}
          </BodyRegular>

          <BodyRegular weight={fontWeight ?? 600} className="weekFlicker__dateLabel weekFlicker__dateLabel--mobile"
                       colour={props.disabled ? Colours.mildGrey : Colours.black}
                       onClick={() => dispatch(openDialog(DialogIdentifiers.ChangeCalendarDateDialog))}>
            {dateLabel}
            <Icon className="weekFlixcker__calendar"
                  color={props.disabled ? 'grey' : 'black'}
                  icon={IconTypes.Calendar} />
          </BodyRegular>
        </>}

        <Row>
          {!dateInTheMiddle && <Icon dataTest="week-flicker-previous-desktop"
                                     circle={true}
                                     color={props.disabled ? 'grey' : 'black'}
                                     className={`weekFlicker__icon--tablet`}
                                     icon={IconTypes.Previous}
                                     onClick={() => changeDate(-1)} />}

          <Icon circle={true}
                icon={IconTypes.Next}
                color={props.disabled ? 'grey' : 'black'}
                onClick={() => changeDate(1)}
                dataTest="week-flicker-next-desktop"/>
        </Row>
      </div>
    </div>
  )
}

interface Props {
  className?: string;
  disabled?: boolean;
  showDate?: boolean;
}
