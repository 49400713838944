import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { TeamEntity } from "../../../models/team.models";
import { AppState } from "../../../store/state/app.state";
import { failureNotification, successNotification } from "../../../store/ducks/notification.duck";
import { redirectTo } from '../../../store/ducks/auth.duck';
import {
  createTeamEventRequest,
  deleteTeamEventRequest,
  getTeamEvents,
  requestTeamActivitiesFor, requestWorkGroupActivitiesFor,
  updateTeamEventRequest
} from "../../../services/WallPlannerService";
import { CreateTeamOptions, TeamEvent } from "../../../models/wall-planner.models";
import { dedup } from "../../../utils/ArrayUtils";
import { DATE_FORMAT } from "../../../utils/DateUtils";
import { Tag } from "../../../models/tags.models";

export interface WallPlannerState {
  loading: boolean;
  favouredTeamsEdited: boolean;
  workGroups: Tag[];
  teamProjects: TeamEvent[];
  teamDetails: any; // TeamActivities
  workGroupDetails: any; // TeamActivities
}

export const initialWallPlannerState: WallPlannerState = {
  loading: false,
  favouredTeamsEdited: false,
  workGroups: [],
  teamProjects: [],
  teamDetails: {},
  workGroupDetails: {},
}

export const createTeamEvent: any = createAsyncThunk(
  'wallPlanner/createTeamEvent',
  async (createTeamOptions: CreateTeamOptions, thunkAPI) => {
    try {
      if (createTeamOptions.id) {
        await updateTeamEventRequest(createTeamOptions);
        thunkAPI.dispatch(successNotification('Team event updated'));
      } else {
        await createTeamEventRequest(createTeamOptions);
        thunkAPI.dispatch(successNotification('Team event added'));
      }
      thunkAPI.dispatch(loadTeamEvents(createTeamOptions.teamId))
      thunkAPI.dispatch(redirectTo('/work-planner'));
    } catch (err: any) {
      thunkAPI.dispatch(failureNotification(err?.message ?? 'Failed to add team event'));
    }
  }
);

export const deleteTeamEvent: any = createAsyncThunk(
  'wallPlanner/deleteTeamEvent',
  async (request: any, thunkAPI) => {
    try {
      await deleteTeamEventRequest(request.teamEventId);
      thunkAPI.dispatch(successNotification('Team event deleted'));
      thunkAPI.dispatch(loadTeamEvents(request.teamId))
      thunkAPI.dispatch(redirectTo('/work-planner'));
    } catch (err: any) {
      console.log(err)
      thunkAPI.dispatch(failureNotification('Failed to delete team event'));
    }
  }
);

export const loadTeamEvents: any = createAsyncThunk(
  'wallPlanner/loadTeamEvents',
  async (teamId: string, thunkAPI) => {
    if (!teamId) {
      return [];
    }

    const state = thunkAPI.getState() as AppState;
    const startDate = state.dashboard.calendarDate.clone().startOf('month').day('Monday');
    // Race condition and deduping issue here
    return await getTeamEvents(teamId, startDate);
  }
);

export const loadWallPlannerTeamsActivities: any = createAsyncThunk(
  'wallPlanner/loadWallPlannerTeamsActivities',
  async (team: TeamEntity, thunkAPI) => {
    await new Promise((resolve: any) => setTimeout(() => resolve(), Math.random() * 1000 * 0.5));
    const state = thunkAPI.getState() as AppState
    const whereaboutsOptions = state.whereaboutsOptions.whereaboutsOptions;
    const startDate = state.dashboard.calendarDate.clone().startOf('month').day('Monday');
    return await requestTeamActivitiesFor(whereaboutsOptions, team.id, startDate.format(DATE_FORMAT));
  }
);

export const loadWallPlannerWorkGroupActivities: any = createAsyncThunk(
  'wallPlanner/loadWallPlannerWorkGroupActivities',
  async (workGroup: Tag, thunkAPI) => {
    await new Promise((resolve: any) => setTimeout(() => resolve(), Math.random() * 1000 * 0.5));
    const state = thunkAPI.getState() as AppState
    const whereaboutsOptions = state.whereaboutsOptions.whereaboutsOptions;
    const startDate = state.dashboard.calendarDate.clone().startOf('month').day('Monday');
    return await requestWorkGroupActivitiesFor(whereaboutsOptions, workGroup.id, startDate.format(DATE_FORMAT));
  }
);

const wallPlannerSlice = createSlice({
  name: 'wallPlanner',
  initialState: initialWallPlannerState,
  reducers: {
    reset: () => initialWallPlannerState,
    dirtyFavouredTeams: (state) => ({...state, favouredTeamsEdited: true}),
  },
  extraReducers: {
    [createTeamEvent.pending]: (state) => ({...state, loading: true,}),
    [createTeamEvent.reject]: (state) => ({...state, loading: false,}),
    [createTeamEvent.fulfilled]: (state) => ({...state, loading: false,}),

    [createTeamEvent.pending]: (state) => ({...state, loading: true,}),
    [createTeamEvent.reject]: (state) => ({...state, loading: false,}),
    [createTeamEvent.fulfilled]: (state) => ({...state, loading: false,}),

    [loadTeamEvents.pending]: (state) => ({...state, teamProjects: []}),
    [loadTeamEvents.fulfilled]: (state, action) => ({
      ...state,
      teamProjects: dedup([ ...state.teamProjects, ...action.payload, ], (p1: TeamEvent, p2: TeamEvent) => p1.id === p2.id)
    }),

    [loadWallPlannerTeamsActivities.fulfilled]: (state, action) => {
      if (!action.payload || !action.payload.teamId || !action.payload.teamActivities) {
        return state;
      }
      const updatedTeamDetails = {
        ...state.teamDetails,
        [action.payload.teamId]: {whereabouts: action.payload.teamActivities, restricted: action.payload.restricted},
      }
      return {
        ...state,
        teamDetails: updatedTeamDetails
      }
    },

    [loadWallPlannerWorkGroupActivities.fulfilled]: (state, action) => {
      if (!action.payload || !action.payload.workGroupId || !action.payload.teamActivities) {
        return state;
      }
      const updatedTeamDetails = {
        ...state.workGroupDetails,
        [action.payload.workGroupId]: {whereabouts: action.payload.teamActivities, restricted: action.payload.restricted},
      }
      return {
        ...state,
        workGroupDetails: updatedTeamDetails
      }
    },
  }
});

export const {
  reset,
  dirtyFavouredTeams
} = wallPlannerSlice.actions;
export default wallPlannerSlice.reducer;

// Selectors
export const selectTeamDetails = (state: AppState) => state.wallPlanner.teamDetails;
export const selectWorkGroupDetails = (state: AppState) => state.wallPlanner.workGroupDetails;
export const selectTeamProjects = (state: AppState) => state.wallPlanner.teamProjects;
export const selectIsLoading = (state: AppState) => state.wallPlanner.loading;
