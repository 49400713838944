import React from 'react';
import { useSelector } from "react-redux";
import { selectAllDepartments } from "./data/departments.duck";
import Layout from "../../UI/organisms/layout/Layout";
import { Column, Row } from "../../UI/atoms/StructuralLayout";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { TeamContentCardList } from "../company-movements/components/ViewComponents";
import { Link } from "react-router-dom";
import { TeamContentCard } from "../../UI/molecules/TeamContentCard";
import { CreateTagContentCard } from "../../UI/molecules/CreateTagContentCard";
import { CompanyTeamViewSidebar } from "../../UI/molecules/CompanyTeamViewSidebar";
import { Department } from "./data/departments.models";
import { useTranslation } from "react-i18next";

export function DepartmentsListPage(props: Props) {
  const {t} = useTranslation();
  const departments = useSelector(selectAllDepartments);
  const showEditIcon = false;

  const onEditClick = (department: Department) => {

  }

  const onDepartmentChecked = (department: Department, state: boolean) => {

  }

  return (
    <Layout>
      <Row>
        <Column className="companyMovements__movements">
          <HeadlineMedium style={{marginBottom: 24}}>{t('company-view-page.departments')}</HeadlineMedium>

          <TeamContentCardList>
            {departments.map((department: Department, key: number) => (
              <Link to={`/whereabouts/company/departments/${department.id}`} key={key}>
                <TeamContentCard label={department.name}
                                 showEditIcon={showEditIcon}
                                 memberCount={department.count}
                                 onEditClick={() => onEditClick(department)}
                                 checkboxEnabled={showEditIcon}
                                 isChecked={false}
                                 onCheckboxChecked={(state: boolean) => onDepartmentChecked(department, state)} />
              </Link>
            ))}
            <CreateTagContentCard />
          </TeamContentCardList>
        </Column>
        <CompanyTeamViewSidebar />
      </Row>
    </Layout>
  )
}

interface Props {
}
