import { agent } from './agent';

export async function updateCompany(company: any) {
    return await agent.requests.put('/api/company', company);
}

export async function updateCompanyName(companyName: string) {
    return await agent.requests.put('/api/company/name', {name: companyName});
}

export async function requestCompanyInfo() {
    return await agent.requests.get('/api/company-info')
}

export async function requestDomainNameSignUp(enabled: boolean) {
    return await agent.requests.post('/api/company/domain-name', { enabled })
}

export async function requestTenantIdSignUp(enabled: boolean, tenantId?: string) {
    return await agent.requests.post('/api/company/tenant-id', { enabled: enabled, tenantId: tenantId || null })
}

export async function updateCompanySetting(field: 'PRIVATE_GROUPS' | 'SCIM_DEPARTMENTS' | 'DISABLE_SCIM_WELCOME_EMAILS', value: any) {
    return await agent.requests.post('/api/company/update', { field, value })
}
