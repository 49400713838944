import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { selectAllDepartments } from "./data/departments.duck";
import Layout from "../../UI/organisms/layout/Layout";
import { HeadlineMedium } from "../../UI/atoms/fonts/Headline";
import { useParams } from "react-router";
import { WorkPlannerContainer } from "../../UI/molecules/work-planner/WorkPlannerStructure";
import { WallPlannerDatesRow } from "../wall-planner/components/WallPlannerDatesRow";
import { WallPlannerUserRow } from "../wall-planner/components/WallPlannerUserRow";
import { WithWeeksInCurrentCalendarMonth } from "../../../hooks/WithWeekInCurrentCalendarMonth";
import { WallPlannerDepartmentPlan } from "../wall-planner/components/WallPlannerDepartmentPlan";

export function DepartmentsUserPage(props: Props) {
  const departments = useSelector(selectAllDepartments);
  const params: any = useParams();
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();

  const department = useMemo(() => {
    if (params.departmentId) {
      return departments.find(d => d.id === parseInt(params.departmentId));
    }
  }, [departments, params.departmentId]);

  if (!department) {
    return null;
  }

  return (
    <Layout>
      {department && <>
        <HeadlineMedium style={{marginBottom: 24}}>{department.name}</HeadlineMedium>

        <WorkPlannerContainer id={'workPlannerContainer'}>
          <WallPlannerDatesRow weeksInMonth={weeksInMonth} />
          <WallPlannerDepartmentPlan department={department} isFirst={true} weeksInMonth={weeksInMonth} />
          <WallPlannerUserRow id={'workPlannerRow'} />
        </WorkPlannerContainer>
      </>}
    </Layout>
  )
}

interface Props {
}
